'use client';
import { Formik, FormikProps } from 'formik';
import { UserFormType } from '@/types/types';
import * as yup from 'yup';

import { useAtom } from 'jotai';
import { userFormAtom } from '@/store/cartStore';
// ** import icons
import { UserSquare } from '@phosphor-icons/react/dist/ssr';

// ** import shared components
import { InputField } from '@/components/shared/form';
import { useEffect } from 'react';

const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required('Required*')
    .test('fullName', 'The fullname is required', (value) => {
      return value.trim().split(' ').length >= 2;
    }),
  email: yup
    .string()
    .required('Required*')
    .max(250, 'Email address is too long.')
    .test('email', 'Invalid email', (value) => {
      const emailRegex = new RegExp(
        '^(?!.*\\#\\@)([a-zA-Z0-9]+([._\\-][a-zA-Z0-9]+)*)@(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})$'
      );
      return emailRegex.test(value);
    }),
  cellNumber: yup
    .string()
    .required('Required*')
    .test('cellphone', 'Invalid number', (value) => {
      const regex = new RegExp('^(?:\\+?\\d{1,4}|0)\\d{6,12}$', 'gm');

      return regex.test(value);
    }),
});

interface Props {
  formRef: React.RefObject<FormikProps<UserFormType>>;
  isFormValid: (isValid: boolean) => void;
}

export default function UserForm({ formRef, isFormValid }: Props) {
  const [user, updateUser] = useAtom(userFormAtom);

  const initialValues = {
    fullName: user?.fullName ?? '',
    email: user?.email ?? '',
    cellNumber: user?.cellNumber ?? '',
  };

  const isInitialFormValid = Object.values(initialValues).every(
    (value) => value !== ''
  );

  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      isInitialValid={isInitialFormValid}
      validationSchema={validationSchema}
      innerRef={formRef}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        try {
          // Assume updateUser returns a Promise

          updateUser({
            ...values,
          });

          // Rest of your code here
          // ...
        } catch (error) {
          console.error('Failed to update user:', error);
        } finally {
          // Set submitting to false to enable the form
          setSubmitting(false);
        }
      }}
    >
      {({ isValid }: { isValid: boolean }) => {
        isFormValid(isValid);
        return (
          <div
            className={`form-fields rounded-lg border border-gray-200 bg-white p-8 shadow-sm`}
          >
            <h2 className='flex items-end text-lg text-charcoal'>
              <UserSquare weight='regular' className='mr-2 inline h-8 w-8' />
              Personal details
            </h2>
            <div className='mt-4'>
              <div className='mt-1'>
                <InputField
                  id='fullName'
                  label='Name and surname'
                  name='fullName'
                  autoComplete='given-name'
                  type='text'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>
            </div>

            <div className='mt-4'>
              <div className='mt-1'>
                <InputField
                  id='email'
                  label='Email address'
                  name='email'
                  autoComplete='email'
                  type='text'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>
            </div>
            <div className='mt-4'>
              <div className='mt-1'>
                <InputField
                  id='cellNumber'
                  label='Cellphone number'
                  name='cellNumber'
                  autoComplete='tel'
                  type='text'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

'use client';
import { useCallback, useEffect } from 'react';
import { BillingFormType } from '@/types/types';
import { Formik, FormikProps } from 'formik';
import { billingFormAtom, billingSameAsDeliveryAtom } from '@/store/cartStore';
import { useAtom } from 'jotai';
import { InputField, SelectField } from '@/components/shared/form';
import * as yup from 'yup';
import AutoCompleteField from '@/components/shared/form/AutoCompleteField';

interface Props {
  formRef: React.RefObject<FormikProps<BillingFormType>>;
  isFormValid: (isValid: boolean) => void;
}

const sameAddressOptions = [
  {
    name: 'sameRadio',
    label: 'Use same address as delivery',
    value: 'same',
  },
  {
    name: 'sameRadio',
    label: 'Use different billing address',
    value: 'different',
  },
];

const validationSchema = yup.object().shape({
  billingStreet: yup.string().required('Required*'),
  billingSuburb: yup.string().required('Required*'),
  billingCity: yup.string().required('Required*'),
  billingProvince: yup.string().required('Required*'),
  billingPostCode: yup.string().required('Required*'),
});

export default function BillingForm({ formRef, isFormValid }: Props) {
  const [billing, updateBilling] = useAtom(billingFormAtom);
  const [billingSameAsDelivery, setBillingSameAsDelivery] = useAtom(
    billingSameAsDeliveryAtom
  );

  useEffect(() => {
    if (billingSameAsDelivery === 'same') {
      isFormValid(true);
    } else {
      isFormValid(false);
    }
  }, [billingSameAsDelivery, isFormValid]);

  const initialValues = {
    billingStreet: billing?.billingStreet || '',
    billingSuburb: billing?.billingSuburb || '',
    billingCity: billing?.billingCity || '',
    billingProvince: billing?.billingProvince || '',
    billingPostCode: billing?.billingPostCode || '',
    billingComplex: billing?.billingComplex || '',
  };

  const isInitialFormValid =
    Object.values(initialValues).every((value) => value !== '') ||
    billingSameAsDelivery === 'same';

  const handleRadioChange = useCallback(
    (value: string) => {
      setBillingSameAsDelivery(value);
    },
    [setBillingSameAsDelivery]
  );

  return (
    <div className={`rounded-lg border bg-white p-8 shadow-sm`}>
      <h2 className='mb-2 flex items-end text-lg text-charcoal'>
        Billing details
      </h2>

      <div>
        <label className='text-sm'>
          Select the address that matches your card and payment method.
        </label>

        <fieldset className='py-4'>
          <legend className='sr-only'>Billing address</legend>
          <div className='space-y-6'>
            {sameAddressOptions.map((option) => (
              <div key={option.value} className='flex items-center'>
                <input
                  id={option.value}
                  name='notification-method'
                  type='radio'
                  defaultChecked={option.value === billingSameAsDelivery}
                  onClick={() => handleRadioChange(option.value)}
                  className='input h-6 w-6 border-gray-300 text-charcoal focus:ring-charcoal'
                />
                <label
                  htmlFor={option.value}
                  className='ml-3 block text-sm font-medium leading-6 text-charcoal'
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>

      {billingSameAsDelivery === 'different' && (
        <Formik<BillingFormType>
          enableReinitialize={true}
          innerRef={formRef}
          isInitialValid={isInitialFormValid}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            updateBilling({
              ...values,
            });
          }}
        >
          {({ isValid }: { isValid: boolean }) => {
            isFormValid(isValid);
            return (
              <div className='form-fields mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'>
                <div className='sm:col-span-2'>
                  <div className='sm:col-span-2'>
                    <AutoCompleteField
                      id='billingStreet'
                      label='Street address'
                      name='billingStreet'
                      autoComplete='street-address'
                      type='text'
                      addressType='billing'
                      required={true}
                      className={`input`}
                      labelProps={{
                        className: 'inputLabel',
                      }}
                    />
                  </div>
                </div>

                <div className='sm:col-span-2'>
                  <InputField
                    id='billingComplex'
                    localStorageKey='billingForm'
                    label={`Complex / Building`}
                    name='billingComplex'
                    type='text'
                    optional={true}
                    autoComplete='city'
                    className={`input`}
                    labelProps={{
                      className: 'inputLabel !text-gray-400',
                    }}
                  />
                  <div className='-mb-2 pl-2'>
                    <span className='text-xs text-gray-400'>
                      Complex or Building Name, unit number or floor
                    </span>
                  </div>
                </div>

                <div className='sm:col-span-2'>
                  <InputField
                    id='billingSuburb'
                    localStorageKey='billingForm'
                    label='Suburb'
                    name='billingSuburb'
                    type='text'
                    required={true}
                    className={`input`}
                    labelProps={{
                      className: 'inputLabel',
                    }}
                  />
                </div>

                <div className='sm:col-span-2'>
                  <InputField
                    id='billingCity'
                    localStorageKey='billingForm'
                    label='City'
                    name='billingCity'
                    type='text'
                    autoComplete='city'
                    required={true}
                    className={`input`}
                    labelProps={{
                      className: 'inputLabel',
                    }}
                  />
                </div>

                <div>
                  <SelectField
                    localStorageKey='billingForm'
                    field={{
                      label: 'Province',
                      name: 'billingProvince',
                      fieldType: 'select',
                      description: '',
                      hidden: false,
                      helpText: '',
                      options: [
                        {
                          value: 'Eastern Cape',
                          label: 'Eastern Cape',
                        },
                        {
                          value: 'Free State',
                          label: 'Free State',
                        },
                        {
                          value: 'Gauteng',
                          label: 'Gauteng',
                        },
                        {
                          value: 'KwaZulu-Natal',
                          label: 'KwaZulu-Natal',
                        },
                        {
                          value: 'Limpopo',
                          label: 'Limpopo',
                        },
                        {
                          value: 'Mpumalanga',
                          label: 'Mpumalanga',
                        },
                        {
                          value: 'Northern Cape',
                          label: 'Northern Cape',
                        },
                        {
                          value: 'North West',
                          label: 'North West',
                        },
                        {
                          value: 'Western Cape',
                          label: 'Western Cape',
                        },
                      ],
                      variant: 'outlined',
                      required: true,
                      placeholder: '',
                      objectTypeId: '',
                    }}
                    id={'billingProvince'}
                    name={'billingProvince'}
                    required={true}
                    type={'select'}
                    className={`selectInput`}
                    labelProps={{
                      className:
                        '[&.text-sm]:!text-[16px] [&.text-sm]:transform [&.text-sm]:-translate-y-1 !text-[12px] !top-0.5 after:!bottom-[1px] pt-0.5 pb-1 peer-placeholder-shown:pt-0 px-3 after:!mx-[-11px]',
                    }}
                  />
                </div>

                <div>
                  <InputField
                    localStorageKey='billingForm'
                    id='billingPostCode'
                    label='Postal code'
                    name='billingPostCode'
                    type='text'
                    autoComplete='postal-code'
                    required={true}
                    maxLength={4}
                    minLength={4}
                    className={`input`}
                    labelProps={{
                      className: 'inputLabel',
                    }}
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
}

'use client';
import { useRouter } from 'next/navigation';
import { IKButton } from '@/components/shared/material';
import { isCartSlideOutOpenAtom } from '@/store/cartStore';
import { useSetAtom } from 'jotai';

interface BackButtonProps {
  color: string;
  text: string;
}

export default function BackButton({ color, text }: BackButtonProps) {
  const router = useRouter();
  const setCartSlideOutOpen = useSetAtom(isCartSlideOutOpenAtom);

  const handleBackButtonClick = () => {
    if (
      document.referrer.includes(window.location.host) &&
      document.referrer !== window.location.href
    ) {
      setCartSlideOutOpen(true);
      router.back();
    } else {
      setCartSlideOutOpen(true);
      router.push('/');
    }
  };

  let textColor = 'text-charcoal';
  let textColorHover = 'hover:text-charcoal-700';
  let iconColorHex = '#1D1D1B';

  if (color === 'teal') {
    textColor = 'text-teal';
    textColorHover = 'hover:text-teal-700';
    iconColorHex = '#009EAC';
  }

  return (
    <IKButton
      type='button'
      variant='text'
      className={`flex items-center gap-2 text-sm ${textColor} ${textColorHover}`}
      onClick={() => handleBackButtonClick()}
    >
      <>
        <svg
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M15.75 8.99982C15.75 9.14901 15.6907 9.29208 15.5852 9.39757C15.4797 9.50306 15.3366 9.56232 15.1875 9.56232H4.17019L8.27292 13.6644C8.32519 13.7166 8.36664 13.7787 8.39493 13.8469C8.42321 13.9152 8.43777 13.9884 8.43777 14.0623C8.43777 14.1362 8.42321 14.2094 8.39493 14.2777C8.36664 14.346 8.32519 14.408 8.27292 14.4603C8.22066 14.5126 8.15862 14.554 8.09033 14.5823C8.02205 14.6106 7.94886 14.6251 7.87495 14.6251C7.80104 14.6251 7.72786 14.6106 7.65957 14.5823C7.59129 14.554 7.52925 14.5126 7.47699 14.4603L2.41449 9.39779C2.36219 9.34555 2.3207 9.28351 2.29239 9.21523C2.26408 9.14694 2.24951 9.07375 2.24951 8.99982C2.24951 8.9259 2.26408 8.85271 2.29239 8.78442C2.3207 8.71613 2.36219 8.6541 2.41449 8.60186L7.47699 3.53936C7.58253 3.43381 7.72569 3.37451 7.87495 3.37451C8.02422 3.37451 8.16738 3.43381 8.27292 3.53936C8.37847 3.6449 8.43777 3.78806 8.43777 3.93732C8.43777 4.08659 8.37847 4.22975 8.27292 4.33529L4.17019 8.43732H15.1875C15.3366 8.43732 15.4797 8.49659 15.5852 8.60208C15.6907 8.70757 15.75 8.85064 15.75 8.99982Z'
            fill={iconColorHex}
          />
        </svg>
        {text ?? ''}
      </>
    </IKButton>
  );
}

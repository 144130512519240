'use client';
import { Truck } from '@phosphor-icons/react/dist/ssr';
import * as yup from 'yup';
import { DeliveryFormType } from '@/types/types';
import { Formik, FormikProps } from 'formik';
import { deliveryFormAtom } from '@/store/cartStore';
import { useAtom } from 'jotai';
import { InputField, SelectField } from '@/components/shared/form';
import AutoCompleteField from '@/components/shared/form/AutoCompleteField';
import { testIds } from '@/constants/testIds';

interface Props {
  formRef: React.RefObject<FormikProps<DeliveryFormType>>;
  isFormValid: (isValid: boolean) => void;
}
const validationSchema = yup.object().shape({
  deliveryStreet: yup
    .string()
    .required('Required*')
    .test('deliveryStreet', 'The street address is required', (value) => {
      return !!value.trim();
    }),
  deliverySuburb: yup
    .string()
    .required('Required*')
    .test('deliverySuburb', 'The suburb is required', (value) => {
      return !!value.trim();
    }),
  deliveryCity: yup
    .string()
    .required('Required*')
    .test('deliveryCity', 'The city is required', (value) => {
      return !!value.trim();
    }),
  deliveryProvince: yup
    .string()
    .required('Required*')
    .test('deliveryProvince', 'The province is required', (value) => {
      return !!value.trim();
    }),
  deliveryPostCode: yup
    .string()
    .required('Required*')
    .test('deliveryPostCode', 'The postal code is required', (value) => {
      return !!value.trim();
    }),
});

export default function DeliveryForm({ formRef, isFormValid }: Props) {
  const [delivery, updateDelivery] = useAtom(deliveryFormAtom);
  const initialValues = {
    deliveryStreet: delivery?.deliveryStreet || '',
    deliverySuburb: delivery?.deliverySuburb || '',
    deliveryCity: delivery?.deliveryCity || '',
    deliveryProvince: delivery?.deliveryProvince || '',
    deliveryPostCode: delivery?.deliveryPostCode || '',
    deliveryComplex: delivery?.deliveryComplex || '',
  };

  const isInitialFormValid = Object.values(initialValues).every((value) => {
    return (value || '').trim() !== '';
  });

  return (
    <div className={`rounded-lg border bg-white p-8 shadow-sm`}>
      <h2 className='mb-2 flex items-end text-lg text-charcoal'>
        <Truck weight='regular' className='mr-2 inline h-8 w-8' />
        Delivery details
      </h2>

      <Formik<DeliveryFormType>
        enableReinitialize={true}
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        isInitialValid={isInitialFormValid}
        onSubmit={(values) => {
          updateDelivery({
            ...values,
          });
        }}
      >
        {({ isValid }: { isValid: boolean }) => {
          isFormValid(isValid);
          return (
            <div
              data-test-qa={testIds.purchaseJourneyDeliveryAddress}
              className='form-fields mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'
            >
              <div className='sm:col-span-2'>
                <AutoCompleteField
                  id='deliveryStreet'
                  label='Street address'
                  name='deliveryStreet'
                  autoComplete='street-address'
                  type='text'
                  addressType='delivery'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>

              <div className='sm:col-span-2'>
                <InputField
                  id='deliveryComplex'
                  localStorageKey='deliveryForm'
                  label={`Complex / Building`}
                  name='deliveryComplex'
                  type='text'
                  optional={true}
                  autoComplete='city'
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel !text-gray-400',
                  }}
                />
                <div className='-mb-2 pl-2'>
                  <span className='text-xs text-gray-400'>
                    Complex or Building Name, unit number or floor
                  </span>
                </div>
              </div>

              <div className='sm:col-span-2'>
                <InputField
                  id='deliverySuburb'
                  localStorageKey='deliveryForm'
                  label='Suburb'
                  name='deliverySuburb'
                  type='text'
                  autoComplete='suburb'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>

              <div className='sm:col-span-2'>
                <InputField
                  localStorageKey='deliveryForm'
                  id='deliveryCity'
                  label='City / Town'
                  name='deliveryCity'
                  type='text'
                  autoComplete='city'
                  required={true}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>

              <div>
                <SelectField
                  localStorageKey='deliveryForm'
                  field={{
                    label: 'Province',
                    name: 'deliveryProvince',
                    fieldType: 'select',
                    description: '',
                    hidden: false,
                    helpText: '',
                    options: [
                      {
                        value: 'Eastern Cape',
                        label: 'Eastern Cape',
                      },
                      {
                        value: 'Free State',
                        label: 'Free State',
                      },
                      {
                        value: 'Gauteng',
                        label: 'Gauteng',
                      },
                      {
                        value: 'KwaZulu-Natal',
                        label: 'KwaZulu-Natal',
                      },
                      {
                        value: 'Limpopo',
                        label: 'Limpopo',
                      },
                      {
                        value: 'Mpumalanga',
                        label: 'Mpumalanga',
                      },
                      {
                        value: 'Northern Cape',
                        label: 'Northern Cape',
                      },
                      {
                        value: 'North West',
                        label: 'North West',
                      },
                      {
                        value: 'Western Cape',
                        label: 'Western Cape',
                      },
                    ],
                    variant: 'outlined',
                    required: true,
                    placeholder: '',
                    objectTypeId: '',
                  }}
                  id={'deliveryProvince'}
                  name={'deliveryProvince'}
                  required={true}
                  type={'select'}
                  className={`selectInput`}
                  labelProps={{
                    className:
                      '[&.text-sm]:!text-[16px] [&.text-sm]:transform [&.text-sm]:-translate-y-1 !text-[12px] !top-0.5 after:!bottom-[1px] pt-0.5 pb-1 peer-placeholder-shown:pt-0 px-3 after:!mx-[-11px]',
                  }}
                />
              </div>

              <div>
                <InputField
                  id='deliveryPostCode'
                  localStorageKey='deliveryForm'
                  label='Postal code'
                  name='deliveryPostCode'
                  type='text'
                  autoComplete='postal-code'
                  required={true}
                  maxLength={4}
                  minLength={4}
                  className={`input`}
                  labelProps={{
                    className: 'inputLabel',
                  }}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

'use client';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import useCart from '@/hooks/useCart';
import { useAtomValue } from 'jotai';
import { cartAtom } from '@/store/cartStore';
const validationSchema = yup.object().shape({
  discountCode: yup.string().required(''),
});

export default function DiscountForm() {
  const { addDiscount } = useCart();
  const cart = useAtomValue(cartAtom);
  const [discountIsOpen, setDiscountIsOpen] = useState(false);
  const [error, setError] = useState('');
  const handleSubmit = useCallback(
    async (values: any, { setSubmitting }: any) => {
      setSubmitting(true);
      const response = await addDiscount(values.discountCode);
      if (!response.success) {
        setError(response.message);
      }
      setSubmitting(false);
    },
    [addDiscount]
  );

  if (cart?.discountCode !== '' || cart?.discountCode === null) {
    return null;
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        discountCode: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, handleBlur, handleChange, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className='pb-2 pt-4'>
            <div className='flex items-center justify-between'>
              <span className='text-base'>Discount / Promo</span>
              <button
                onClick={() => setDiscountIsOpen(!discountIsOpen)}
                type='button'
                className={`hover:boder-gray-700 !hover:bg-gray-200 rounded-lg border border-gray-600 bg-gray-200 px-8 py-2 text-sm uppercase text-charcoal hover:bg-gray-300`}
                color='white'
              >
                {!discountIsOpen ? 'Add code' : 'Cancel'}
              </button>
            </div>
            {discountIsOpen && (
              <div className='w-100 mt-4'>
                {discountIsOpen && (
                  <>
                    <div className='mt-2 flex w-full rounded-md'>
                      <input
                        placeholder='Discount code'
                        type='text'
                        id='discountCode'
                        className='w-full rounded-lg rounded-r-none border border-r-0 border-gray-600 px-4 py-2 disabled:bg-gray-100'
                        name='discountCode'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        value={values.discountCode}
                      ></input>
                      <button
                        type='submit'
                        disabled={isSubmitting}
                        className={`!hover:bg-gray-200 flex flex-nowrap items-center gap-2 rounded-lg rounded-l-none border border-l-0 border-gray-600 bg-gray-200 px-8 py-2 text-sm uppercase text-charcoal hover:border-gray-700`}
                      >
                        Apply
                        {isSubmitting && (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            className='h-4 w-4 animate-spin'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                    {error && (
                      <p className='mt-2 text-sm text-red-500'>{error}</p>
                    )}
                  </>
                )}
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
}
